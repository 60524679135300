import React from 'react'
import type { NextPage } from 'next'
import MainTemplate from '@/components/layout/main'
import { ErrorPage, ErrorPageProps } from '@/components/ui/organisms/ErrorPage'

const NotFoundPage: NextPage<ErrorPageProps> = (props) => {
  return (
    <MainTemplate>
      <ErrorPage {...props} statusCode={props.statusCode || 404} title="This page could not be found." />
    </MainTemplate>
  )
}

export default NotFoundPage
