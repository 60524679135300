'use client'

import React from 'react'
import Error from 'next/error'
import { useRouter } from 'next/router'
import classnames from 'classnames'
import { AUTH0_PATH } from '@/components/layers/Auth0Layer'
import { useNextAuth } from '@/core/hooks/useNextAuth'
import styles from './ErrorPage.module.scss'

export interface ErrorPageProps extends Awaited<ReturnType<(typeof Error)['getInitialProps']>> {}

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  console.warn({ props })
  const { isLoggedIn } = useNextAuth()
  const router = useRouter()

  // TODO: Fix on done with auth
  const onLogin = () => router.push({ pathname: AUTH0_PATH.LOGIN })

  return (
    <div className={classnames(styles.container, { [styles.fullHeight]: !isLoggedIn })}>
      <Error statusCode={props.statusCode} title={props.title} />
      {!isLoggedIn && (
        <div className={styles.loginBtn} onClick={onLogin}>
          Login
        </div>
      )}
    </div>
  )
}
